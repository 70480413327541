import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.timers.js";
import { log } from './gtm.utils';
var _layer = 'dataLayer';
var _id = 'GTM-NZLF7FM3';
function gtmClient(ctx, initialized) {
  return {
    init: function init() {
      var id = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _id;
      if (initialized[id] || !window._gtm_inject) {
        return;
      }
      window._gtm_inject(id);
      initialized[id] = true;
      log('init', id);
    },
    push: function push(obj) {
      if (!window[_layer]) {
        window[_layer] = [];
      }
      window[_layer].push(obj);
      log('push', obj);
    }
  };
}
function gtmServer(ctx, initialized) {
  var events = [];
  var inits = [];
  ctx.beforeNuxtRender(function () {
    if (!inits.length && !events.length) {
      return;
    }
    var gtmScript = ctx.app.head.script.find(function (s) {
      return s.hid == 'gtm-script';
    });
    gtmScript.innerHTML = "window['".concat(_layer, "']=").concat(JSON.stringify(events), ";").concat(gtmScript.innerHTML);
    if (inits.length) {
      gtmScript.innerHTML += ";".concat(JSON.stringify(inits), ".forEach(function(i){window._gtm_inject(i)})");
    }
    var gtmIframe = ctx.app.head.noscript.find(function (s) {
      return s.hid == 'gtm-noscript';
    });
    var renderIframe = function renderIframe(id) {
      return "<iframe src=\"https://www.googletagmanager.com/ns.html?id=".concat(id, "&\" height=\"0\" width=\"0\" style=\"display:none;visibility:hidden\" title=\"gtm\"></iframe>");
    };
    if (inits.length) {
      gtmIframe.innerHTML += inits.map(renderIframe);
    }
  });
  return {
    init: function init() {
      var id = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _id;
      if (initialized[id]) {
        return;
      }
      inits.push(id);
      initialized[id] = true;
      log('init', id);
    },
    push: function push(obj) {
      events.push(obj);
      log('push', JSON.stringify(obj));
    }
  };
}
function startPageTracking(ctx) {
  ctx.app.router.afterEach(function (to) {
    setTimeout(function () {
      ctx.$gtm.push(to.gtm || {
        routeName: to.name,
        pageType: 'PageView',
        pageUrl: '' + to.fullPath,
        pageTitle: typeof document !== 'undefined' && document.title || '',
        event: 'nuxtRoute'
      });
    }, 250);
  });
}
export default function (ctx, inject) {
  var runtimeConfig = ctx.$config && ctx.$config.gtm || {};
  var autoInit = false;
  var id = 'GTM-NZLF7FM3';
  var runtimeId = runtimeConfig.id;
  var initialized = autoInit && id ? _defineProperty({}, id, true) : {};
  var $gtm = process.client ? gtmClient(ctx, initialized) : gtmServer(ctx, initialized);
  if (autoInit && runtimeId && runtimeId !== id) {
    $gtm.init(runtimeId);
  }
  ctx.$gtm = $gtm;
  inject('gtm', ctx.$gtm);
}